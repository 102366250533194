export const loginUrl = '/api/admin/login'
export const logOut = '/api/admin/logout'
export const userDetails = '/api/admin/user-details'
export const getAllTripList = '/api/admin/get-trip-list-for-booking'
export const getAllBookingRouteListUrl = '/api/admin/get-route-list-for-booking'
export const getAllTransportListUrl = '/api/admin/get-transport-list-for-booking'
export const getAllRegionListUrl = '/api/admin/get-region-list-for-booking'

export const createAdminUserUrl = '/api/admin/create-admin-user'
export const updateAdminUserUrl = '/api/admin/update-admin-user'
export const getAllAdminUsersListUrl = '/api/admin/get-admin-user-list'

export const createStaffUserUrl = '/api/admin/create-staff'
export const updateStaffUserUrl = '/api/admin/update-staff'
export const getAllStaffUsersListUrl = '/api/admin/get-staff-list'

export const createAgentUserUrl = '/api/admin/create-agent'
export const updateAgentUserUrl = '/api/admin/update-agent'
export const getAllAgentUsersListUrl = '/api/admin/get-agent-list'

export const createDriverUserUrl = '/api/admin/create-driver'
export const updateDriverUserUrl = '/api/admin/update-driver'
export const getAllDriverUsersListUrl = '/api/admin/get-driver-list'
export const assignDriverToTransportUrl = '/api/admin/assign-transport-driver'


export const getAllRegionListForTableUrl = '/api/admin/get-region-list'
export const getRegionDetailsUrl = '/api/admin/get-region-details'
export const createNewRegionUrl = '/api/admin/create-region'
export const updateAdminRegionUrl = '/api/admin/update-region'

export const getAllTransportListForTableUrl = '/api/admin/get-transport-list'
export const createNewTransportUrl = '/api/admin/create-transport'
export const updateTransportUrl = '/api/admin/update-transport'
export const getMastersForTransportUrl = '/api/admin/get-maters-for-transport'

export const getAllTripListForTableUrl = '/api/admin/get-trip-list'
export const createNewTripUrl = '/api/admin/create-trip'
export const updateTripUrl = '/api/admin/update-trip'

export const getAllRouteListForTableUrl = '/api/admin/get-route-list'
export const createNewRouteUrl = '/api/admin/create-route'
export const updateRouteUrl = '/api/admin/update-route'

export const getAllPriceListForTableUrl = '/api/admin/get-price-list'
export const createNewPriceUrl = '/api/admin/create-price-list'
export const updatePriceUrl = '/api/admin/update-price-list'
export const getPriceListForDropdownUrl = '/api/admin/get-price-list-for-booking'
export const assignRegionToTransportUrl = '/api/admin/assign-transport-region'

export const getPriceListForBookingUrl = '/api/admin/get-price-list-for-booking'
export const assignTransportTripTimingUrl = '/api/admin/assign-transport-trip-timing'
export const getTransportDetailsUrl = '/api/admin/get-transport-details'

export const getAllTimingListForTableUrl = '/api/admin/get-timing-list'
export const createNewTimingUrl = '/api/admin/create-timing'
export const updateTimingUrl = '/api/admin/update-timing'
export const getTimingForDropdownUrl = '/api/admin/get-timing-list-for-dropdown'
export const getTripAndTimingForDropdownUrl = '/api/admin/trip-timing-not-assigned'

export const createStoppageUrl = '/api/admin/create-stopage'
export const stoppageListUrl = '/api/admin/get-stopage-list'
export const updateStoppageUrl = '/api/admin/update-stopage'
export const getStoppageListForDropdownUrl = '/api/admin/get-stopage-list-dropdown'


//create booking
export const createBookingUrl = '/api/admin/create-booking'
export const uploadFileUrl = '/api/admin/get-upload-file'
export const allMastersListUrl = '/api/admin/get-masters-list-for-booking'
export const getAllBookingListUrl = '/api/admin/get-booking-list'
export const searchBookingListUrl = '/api/admin/search-booking'
export const getBookingDetailsUrl = '/api/admin/get-booking-details'
export const getBookingDetailsForUpdateUrl = '/api/admin/get-seat-wise-booking-list'
export const updateBookingUrl = '/api/admin/update-booking'
export const getBookingChangeHistoryList = '/api/admin/get-booking-change-history-list'
export const removeTripAndTimeAssignedToTransport = '/api/admin/remove-transport-assignment-trip-timing'

export const reserveUnreserveBooking = '/api/admin/reserve-unreserve-seat'
export const searchBookingListDataForTicketPrint = '/api/admin/booking-customer/search'

export const generateHtml = '/api/admin/generate-booking-pdf'
// age calculate age
export const ageCalculateUrl = '/api/admin/get-age-from-dob'

//visa 
export const getAllVisaRequsetUrl = '/api/admin/get-visa-request-list'


export const getCheckInList = '/api/admin/get-checkin-group-list'
export const generateCheckinExcel = '/api/admin/generate-checkin-excel'


export const CREATE_PUT_DELETE_DEPOT = '/api/admin/depot/'

// consignment
export const CREATE_GET_CONSIGNMENT = '/api/admin/consignments/'
export const CREATE_GET_CONSIGNMENT_TRACKING = '/api/admin/consignments/'
export const GET_CONSIGNMENT_TRACKING_HISTORY = '/api/admin/consignments/statuses'
export const UPDATE_CONSIGNMENT_PAYMENT_STATUS = '/api/admin/consignments/update'

export const CREATE_GET_SALES_REPORT = '/api/admin/sales'

export const CREATE_POST_EXIT_FEE_STATUS = 'api/admin/exit-fee/pay'

// for accountant
export const CREATE_PUT_DELETE_ACCOUNTANT = '/api/admin/accountant'

// for expense

export const CREATE_PUT_DELETE_EXPENSE = 'api/admin/expense'
export const SEND_EXPENSE_APPROVE_REQUEST = '/api/admin/expense/update-status'

// get user list of branch or all

export const CREATE_GET_USER_LIST = '/api/admin/user-list'
export const CREATE_GET_BRANCH_LIST = '/api/admin/branch-list'

// deposit slips
export const CREATE_GET_DEPOSIT_SLIP = '/api/admin/deposit-slips'
// check in detail
export const GET_CHECK_IN_DETAIL = '/api/admin/get-checkin-passenger'

// balance sheet
export const GET_CREATE_PUT_BALANCE_SHEET = '/api/admin/balance-sheets'
export const GET_CREATE_PUT_INCOME_STATEMENT = '/api/admin/income-statements'
export const GET_CREATE_PUT_TRAIL_BALANCE = '/api/admin/trail-balance'


//generate
export const GET_BILL_No = '/api/admin/invoice/generate-bill-no'
export const GET_CUSTOMER_No = '/api/admin/invoice-customers/generate-customer-code'
export const GET_SERVICE_No = '/api/admin/invoice-services/generate-service-code'
// invoices
export const GET_CREATE_PUT_INVOICE = '/api/admin/invoice'
// services
export const GET_CREATE_PUT_SERVICE_No = '/api/admin/invoice-services'
// customers
export const GET_CREATE_PUT_CUSTOMER_No = '/api/admin/invoice-customers'

// bus management
export const GET_CREATE_PUT_BUS = '/api/admin/buses'
export const CREATE_BUS_DOCUMENT = '/api/admin/bus-document'

// refund ticket
export const CREATE_REFUND_REQUEST = '/api/admin/refund-requests'
// booking check-in
export const REQUEST_CHECK_IN = '/api/admin/booking-checkin'
// change pwd
export const POST_CHANGE_PASSWORD = '/api/admin/change-password'
// agent top up
export const CREATE_GET_AGENT_DEPOSIT_SLIP = '/api/admin/topup'

export const CREATE_GET_AGENTS_SALES_REPORT = '/api/admin/transactions'

export const RESET_TO_INITIAL_PWD = '/api/admin/reset-password'

export const TRIP_SUMMARY_PWD = '/api/admin/trip-summary'

export const DEDUCT_AGENT_WALLET = '/api/admin/agent-topup/deduct-wallet'